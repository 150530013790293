<template>
  <div class="websiteEng">
    <div class="content" :class="isPhone ? 'phone_content':''" :style="clientWidth < 1400 ? 'width: 800px':'width: 1230px; margin-left: calc(50% - 700px);'">
      <el-carousel id="home" class="img-box" :style="clientWidth < 1400 ? 'width: 800px; ':'width: 1200px;'" :interval="5000" :autoplay="true" 
        :height="isPhone ?  clientWidth / zoom * (340/800) + 'px': clientWidth < 1400 ? '340px':'500px'">
        <el-carousel-item v-for="(item,index) in bannerList" :key="index">
          <img
            :src="item"
          />
        </el-carousel-item>
      </el-carousel>
      <div id="about" class="box">
        <div class="box-header">
          <img :src="imgURL+'about_ym'+ (clientWidth < 1400 ? '':'_12')+'.png'" alt="" :style="clientWidth < 1400 ? 'scale: 0.8;':''">
        </div>
        <div class="head">
          <div class="item">
            <img :src="imgURL+'icon_company.png'" alt="">
            <div>
              <p>Our vision</p>
              <p>To be a world leader in hazardous materials logistics.</p>
            </div>
          </div>          
          <div class="item">
            <img :src="imgURL+'icon_company.png'" alt="">
            <div>
              <p>Our Mission</p>
              <p>To guard safety，to ensure smooth logistics.</p>
            </div>
          </div>          
          <div class="item">
            <img :src="imgURL+'icon_company.png'" alt="">
            <div>
              <p>Industry Certification</p>
            </div>
          </div>          
        </div>
        <div class="certificate">
          <div class="item" v-for="(item,index) in certificateList" :key="index">
            <img :src="imgURL+item.url" alt="">
            <div class="mark">{{ item.code }}</div>
          </div>
        </div>
      </div>
      <div id="our_histoty" class="box">
        <div class="box-header">
          <img :src="imgURL+'our_histoty'+ (clientWidth < 1400 ? '':'_12')+'.png'" alt="" :style="clientWidth < 1400 ? 'scale: 0.8;':'scale: 1;'">
          <p class="tips">We hope to and are willing to grow with our customers!</p>
        </div>
        <div class="desc">
          <p>Established in 2008.</p>
          <p> Focused on global logistics services for dangerous goods and immediately designed dangerous goods sea transportation LCL service products, providing innovative standard solutions for global logistics of small quantities of dangerous goods. Now provide services such as air transportation, land transportation, and multi-modal transportation of DG goods.</p>
          <p>In 2017, the company was renamed as Y and M Logistics Technology Co., Ltd., and designed and developed an online service platform for dangerous goods called "Yundida". Provide customers with online logistics management, improve logistics management efficiency and cost control, and continuously design and launch innovative logistics solutions for industries such as chemicals, pesticides, pharmaceuticals, battery, EV, ro-ro ship and cage car, etc.</p>
          <p><img :src="imgURL+'our_his_img.png'" alt=""></p>
        </div>
      </div>
      <div id="img_products" class="box">
        <p><img :src="imgURL+'img_products.png'" alt=""></p>
      </div>
      <div id="our_adv" class="box" :class="isPhone ? 'phone_our_adv':''">
        <div class="box-header">
          <img :src="imgURL+'our_adv'+ (clientWidth < 1400 ? '':'_12')+'.png'" alt="" :style="clientWidth < 1400 ? 'scale: 0.8;':'scale: 1;'">
          <p class="tips">New energy product transportation</p>
        </div>

        <p class="text-r">Motor transport cases <span class="green">(Ro-ro ship、Cage car、container)</span></p>
        <div class="item">
          <img v-for="(item,index) in dataList.motor" :key="index" :src="imgURL+item" alt="">
        </div>

        <p>Packing Cases <span class="green">(LCL/FCL of DG cargo)</span></p>
        <div class="item">
          <img v-for="(item,index) in dataList.packing" :key="index" :src="imgURL+item" alt="">
        </div>

        <p class="text-r">Cases <span class="green">(New Energy Cargo)</span></p>
        <div class="item">
          <img v-for="(item,index) in dataList.cases" :key="index" :src="imgURL+item" alt="">
        </div>

        <p>Storage cases <span class="green">(New Energy Cargo)</span></p>
        <div class="item">
          <img v-for="(item,index) in dataList.storage" :key="index" :src="imgURL+item" alt="">
        </div>

        <p class="text-r">Export packaging cases <span class="green">(EV Cars、Battery)</span></p>
        <div class="item">
          <img v-for="(item,index) in dataList.export_packaging" :key="index" :src="imgURL+item" alt="">
        </div>
      </div>
      <div id="img_yundida" class="box">
        <p><img :src="imgURL+'img_yundida.png'" alt=""></p>
      </div>
      <div v-if="isPhone" id="phonecontact">
        <div class="list">
          <div class="item">
            Y and M Logistics Technology <br> Co.,Ltd.
          </div>
          <div class="item font14">
            Tel: +021-51087288-8015 <br>
            Email: overseas@ymlogistics.net
          </div>
          <div class="item">
            Address of company
            <span class="font14" style="margin-top: 15px;margin-bottom: 15px; display: inline-block;">Address:Room509,Anlian Building, 168Jingzhou Road,Yangpu District, Shanghai</span>
            <img :src="imgURL + 'address_company.png'" alt="" style="width: 100%;">
          </div>
          
        </div>
        <div class="list">
          <div class="item">
            Address of warehouse
            <span class="font14" style="margin-top: 15px;margin-bottom: 15px; ; display: inline-block;">
              Address:No.268 Tianxue Road,Nabhui New Town,Pudong New District,Shanghai</span>
            <img :src="imgURL + 'address_warehouse.png'" alt="" style="width: 100%;">
          </div>
        </div>
      </div>
      <div v-if="isPhone" id="phonecontactus" @click="dialogVisibleFn">
        Contact US
      </div>
    </div>
    <div class="directory" :style="clientWidth < 1400 ? 'display: none; left:0; z-index:9;':'left:calc(50% - 833px)'">
      <div class="logo" :style="clientWidth < 1400 ? 'width: calc(50% - 410px); display: none;':'width: calc(50% - 700px)'">
        <img :src="imgURL+'ym_logo.png'" alt="">
      </div>
      <div class="list" :style="clientWidth < 1400 ? 'margin-top: 20px':'margin-top: 60px'">
        <div class="item" @click="activeFunc(item)" :class="active == item.id ? 'active':''" v-for="(item, index) in navList" :key="index">
          <a :href="'#'+item.id">
            <img :src="(active == item.id ? imgURL+ item.icon+'_active': imgURL+item.icon) + '.png'" alt="">
            <span>{{ item.name }}</span>
          </a>
        </div>
      </div>
    </div>
    <!-- 联系我们 -->
    <div v-if="!isPhone" id="contact" :style="clientWidth < 1400 ? 'right: 0 !important; left: auto; width: 240px; background: #233041; z-index: 9; zoom: 0.7' :'left: calc(50% + 528px)'">
      <div class="list" :style="clientWidth < 1400 ? 'width: 200px':'width: 260px'">
        <div class="item">
          Y and M Logistics Technology <br> Co.,Ltd.
        </div>
        <div class="item font14">
          Tel: +021-51087288-8015 <br>
          Email: overseas@ymlogistics.net
        </div>
        <div class="item">
          Address of company
          <span class="font14" style="margin-top: 15px;margin-bottom: 15px; display: inline-block;">Address:Room509,Anlian Building, 168Jingzhou Road,Yangpu District, Shanghai</span>
          <img :src="imgURL + 'address_company.png'" alt="" style="width: 100%;">
        </div>
        <div class="item" style="margin-top: 55px;">
          Address of warehouse
          <span class="font14" style="margin-top: 15px;margin-bottom: 15px; ; display: inline-block;">
            Address:No.268 Tianxue Road,Nabhui New Town,Pudong New District,Shanghai</span>
          <img :src="imgURL + 'address_warehouse.png'" alt="" style="width: 100%;">
        </div>
      </div>
      <div class="contact_us" @click="dialogVisible = true" :style=" clientWidth < 1400 ? 'height:36px':'height: 46px;'">
        Contact US <i class="el-icon-right"></i>
      </div>
    </div>

    <el-dialog
      title="Contact us"
      :visible.sync="dialogVisible"
      width="652px"
      :fullscreen="isPhone ? true:false"
      :before-close="handleClose">
      <div class="userlist" :class="isPhone ?'userphonelist':''">
        <div class="item" v-for="(item, index) in userList" :key="index">
          <p><img :src="imgURL+'icon_user.png'" alt=""><span class="green">{{ item.name }}</span></p>
          <p><img :src="imgURL+'icon_email.png'" alt="">{{ item.email }}</p>
          <p><img :src="imgURL+'icon_phone.png'" alt=""><a :href="'tel:'+item.phone">{{ item.phone }}</a></p>
        </div>
      </div>
    </el-dialog>
    
  </div>
</template>

<script>
export default {
  name: "websiteEng",
  data() {
    return {
      bannerList: [
        'https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/websiteEng/banner1.jpg',
        'https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/websiteEng/banner2.jpg',
        'https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/websiteEng/banner3.jpg',
        'https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/websiteEng/banner4.jpg',
        'https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/websiteEng/banner5.jpg',
        // 'https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/websiteEng/banner6.jpg',
      ],
      imgURL:'https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/websiteEng/',
      active: 'home',
      certificateList:[
        {
          code:'NVOCC',
          url:'img_nvocc.png'
        },{
          code:'BLN',
          url:'img_bln.png'
        },{
          code:'WIFFA',
          url:'img_wiffa.png'
        },
      ],
      navList:[
        {
          name: 'HOME',
          icon: 'icon_home',
          id: 'home'
        },{
          name: 'About Y&M',
          icon: 'icon_about',
          id: 'about'
        },{
          name: 'Our History',
          icon: 'icon_his',
          id: 'our_histoty'
        },{
          name: 'Products',
          icon: 'icon_products',
          id: 'img_products'
        },{
          name: 'Yundida',
          icon: 'icon_ydd',
          id: 'img_yundida'
        },{
          name: 'Contact',
          icon: 'icon_contact',
          id: 'contact'
        },
      ],
      screenW: window.screen.width,
      // clientWidth: window.screen.width,
      clientWidth: document.documentElement.clientWidth,
      zoom: 1,
      isPhone: false,
      dialogVisible: false,
      dataList: {
        motor:[
          'adv13.png',
          'adv14.png',
          'adv15.png',
        ],
        packing:[
          'adv1.png',
          'adv2.png',
          'adv3.png',
        ],
        cases:[
          'adv4.png',
          'adv5.png',
          'adv6.png',
        ],
        storage:[
          'adv7.png',
          'adv8.png',
          'adv9.png',
        ],
        export_packaging:[
          'adv10.png',
          'adv11.png',
          'adv12.png',
        ],
      },
      userList:[{
          name:"Cici",
          email:'overseas.op4@ymlogistics.net',
          phone:'13818749382'
        },{
          name:"Tina",
          email:'overseas.op2@ymlogistics.net',
          phone:'13916141848'
        },{
          name:"Crystal",
          email:'chenying@ymlogistics.net',
          phone:'19802102727'
        }
      ]
    }
  },
  mounted() {
    let that = this;
    window.onresize = () => {
      return (() => {
        window.fullWidth = document.documentElement.clientWidth;
        that.clientWidth = window.fullWidth; // 宽
      })()
    };
    let c = document.querySelector('body')
    c.style.zoom = 1 / (this.getRatio()/100);
    this.zoom = 1 / (this.getRatio()/100);
    this.browserRedirect();
    if(this.isPhone == true){
      c.style.minWidth = 'auto'
    }
  },
  methods: {
    activeFunc(item){
      this.active = item.id;
    },
    dialogVisibleFn(){
      let c = document.querySelector('body')
      c.style.zoom = 1;
      this.dialogVisible = true;
    },
    handleClose(){
      let c = document.querySelector('body')
      c.style.zoom = this.zoom;
      this.dialogVisible = false;
    },
    browserRedirect() {
      var sUserAgent = navigator.userAgent.toLowerCase();
      var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
      var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
      var bIsMidp = sUserAgent.match(/midp/i) == "midp";
      var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
      var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
      var bIsAndroid = sUserAgent.match(/android/i) == "android";
      var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
      var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
      if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
        this.isPhone = true;
        return("phone");
      } else {
        this.isPhone = false;
        return("pc");
      }
    },
    //获取屏幕缩放比例
    getRatio() {
      var ratio = 0;
      var screen = window.screen;
      var ua = navigator.userAgent.toLowerCase();

      if (window.devicePixelRatio !== undefined) {
        ratio = window.devicePixelRatio;
      }
      else if (~ua.indexOf('msie')) {
        if (screen.deviceXDPI && screen.logicalXDPI) {
          ratio = screen.deviceXDPI / screen.logicalXDPI;
        }

      }
      else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
        ratio = window.outerWidth / window.innerWidth;
      }

      if (ratio) {
        ratio = Math.round(ratio * 100);
      }
      return ratio;
    }
  }
}
</script>

<style lang="less" scoped>
.websiteEng {
  width: 100%;
  background: #233041;
  box-sizing: border-box;
  overflow-x: hidden;
  .content{
    margin: 0 auto;
    width: 830px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    .img-box{
      width: 800px;
      height: auto;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .box{
      .box-header{
        text-align: center;
        margin-top: 40px;
        margin-bottom: 40px;
        
        .tips{
          color: #999;
          font-size: 16px;
          margin-top: 15px;
        }
      }
      .head{
        display: flex;
        flex-wrap: wrap;
        .item{
          width: 50%;
          padding: 10px 30px;
          display: flex;
          align-items: center;
          img{ margin-right: 20px; }
          line-height: 32px;
          p:nth-child(1){
            border-bottom: 1px solid #EDEDED;
          }
        }
      }
      .certificate{
        margin-top: 20px;
        display: flex;
        justify-content: space-evenly;
        .item{
          width: 220px;
          height: 150px;
          position: relative;
          &:hover{
            .mark{
              opacity: 0.7;
            }
          }
          .mark{
            position: absolute;
            top: 0; left: 0;
            width: 220px;
            height: 150px;
            background: #97C642;
            text-align: center;
            line-height: 150px;
            opacity: 0;
            transition: 0.3s;
            font-size: 28px;
            color: white;
            &:after{
              content:' ';
              position: absolute;
              top: 2px;
              left: 2px;
              display: block;
              width: 212px;
              height: 142px;
              border: 2px solid #fff;
            }
          }
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    #our_histoty{
      width: calc(100% - 60px);
      box-shadow: 0px 1px 6px 0px rgba(149,149,149,0.18);
      margin: 30px 15px 0;
      .desc{
        width: 100%;
        padding: 0 30px;
        p{
          color: #333;
          margin-bottom: 20px;
          img{width: 100%;}
        }
      }
    }
    #our_adv{
      .box-header{
        margin-bottom: 5px;
      }
      p{ font-size: 16px; padding: 5px; padding-bottom: 15px; }
      .item{
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
        img{
          width: 33%;
        }
      }
    }
    .phone_our_adv{
      p{ font-size: 10px !important; }
    }

    #img_products, #img_yundida,  #our_adv{
      width: calc(100% - 60px);
      margin: 30px 15px 0;
    }
    #img_products, #img_yundida{
      img{
        width: 100%;
      }
    }
  }
  .phone_content{
    width: 100% !important;
    
    .img-box{
      width: 100% !important;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .box{
      .box-header{
        .tips{
          font-size: 10px;
        }
      }
    }
  }
  .directory{
    position: fixed;
    top: 0;
    left: calc(50% - 532px);
    background: #233041;
    width: 130px;
    .logo{
      position: fixed;
      top: 0;
      left: 0;
      background-color: white;
      width: calc(50% - 410px);
      height: 60px;
      text-align: right;
      padding: 3px;
      img{margin-right: 20px;}
    }
    .list{
      width: 100%;
      margin-top: 60px;
      .item{
        display: flex;
        align-items: center;
        line-height: 42px;
        cursor: pointer;
        &:hover{
          background: #2A374A;
          border-left: 2px solid #97C642;
        }
        img{
          padding: 5px;
          margin-bottom: 3px;
        }
        span{
          color: white;
        }
      }
      .active{
        background: #2A374A;
        border-left: 2px solid #97C642;
        span{
          color: #97C642;
        }
      }
    }
  }
  #contact, #phonecontact{
    position: fixed;
    top: 0;
    left: calc(50% + 410px);
    width: 300px;
    height: 100%;
    padding: 30px 20px;
    .list{
      .item{
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 30px;
        color: white;
      }
      .font14{
        font-size: 13px;
      }
    }
    .contact_us{
      background: url('https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/websiteEng/contact_us.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 40px;
      margin-top: 55px;
      padding-left: 30px;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      .el-icon-right{
        margin-left: 10px;
      }
    }
  }
  #phonecontactus{
    background: #97C642;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6vh;
    line-height: 100px;
    font-size: 16px;
    font-weight: bolder;
    text-align: center;
    color: white;
  }
  #phonecontact{
    position: static;
    width: 100%;
    background: #233041;
    color: white;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 150px;
    .list{
      width: 48%;
      word-break: break-all;
      .item{
        font-size: 12px;
      }
      .font14{
        font-size: 10px;
      }
    }
  }
  .userlist, .userphonelist{
    width: 100%;
    color: #040000;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .item{
      width: 300px;
      height: 140px;
      border: 1px solid #ECECEB;
      word-break: break-all;
      display: flex;
      flex-direction: column;
      padding: 12px;
      padding-bottom: 0;
      margin-bottom: 12px;
      p{
        border-bottom: 1px dashed #ECECEB;
        line-height: 36px;
        padding-left: 10px;
        font-size: 14px;
        img{
          padding-right: 15px;
          padding-bottom: 5px;
        }
        &:last-child{
          border: none;
          margin-top: 4px;
        }
      }
    }
  }
  .userphonelist{
    .item{
      width: 100%;
      height: 140px;
      border: 1px solid #ECECEB;
      word-break: break-all;
      display: flex;
      flex-direction: column;
      padding: 12px;
      padding-bottom: 0;
      margin-bottom: 12px;
      p{
        border-bottom: 1px dashed #ECECEB;
        line-height: 36px;
        padding-left: 10px;
        img{
          padding-right: 15px;
          padding-bottom: 5px;
        }
        &:last-child{
          border: none;
          margin-top: 4px;
        }
      }
    }
  }



  /deep/ .el-carousel__container {
    height: 100%;
    width: 100%;
  }
  .green{
    color: #97C642;
  }
  .text-r{
    text-align: right;
  }
  /deep/ .el-dialog__header{
    border: none;
    padding-top: 13px;
    font-weight: bolder;
    color: #040000;
  }
}
</style>